@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap');
*{
  scroll-behavior: smooth;
}

html,body{
  height: 100%;
  width: 100%;
}

*::-webkit-scrollbar{
  width: .5em;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(94, 94, 94);
  border-radius: 20px;
}

img{
  object-fit: cover;
}

#root{
  height: 100%;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  color: #fff;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

