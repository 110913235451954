img[alt="rocket"]{
    max-width: 8em;
}

.notFoundContainer{
    position: relative;
    height: calc(100% - 76px);
    margin: auto;
    overflow: hidden;
}

#circle-orbit-container{
    display: flex;
    justify-content: center;
}

#outer-orbit {
    position: absolute;
    width: 80em;
    height: 80em;
    animation: spin-right 9s linear infinite;
}

#circle-orbit-container > img {
    position: absolute;
    bottom: 0;
}

@keyframes spin-right {
    100% {
      transform: rotate(360deg);
    }
}
