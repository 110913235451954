h1 {
  display: inline;
  font-size: 3em;
  font-weight: 600;
}

.typed-cursor {
  font-size: 3em;
  font-weight: 600;
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}

@keyframes typedjsBlink {
  50% {
    opacity: 0.0;
  }
}

@-webkit-keyframes typedjsBlink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1;
  }
}

.typed-fade-out {
  opacity: 0;
  transition: opacity .25s;
  -webkit-animation: 0;
  animation: 0;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
  border-bottom: 2px solid black;
}

h4 {
  font-size: 1.5em;
  font-weight: 500;
}

#imgMain {
  width: 100%;
  animation: astronautAnim 10s ease-in-out infinite;
  filter: drop-shadow(15px 13px 10px #000);
}

p {
  font-size: 1.25em;
}

.grid-path-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-flow: dense;
  grid-auto-rows: 120px;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
}

.path-item {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
  margin: auto;
}

.holidays {
  margin: .5em;
  padding: 1em;
  border: 3px solid black;
  border-radius: .75em;
}

.holidays-path-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.holidays-path-items>img {
  padding: .75em;
}

.grade-achievements {
  display: flex;
  padding-top: 1em;
  justify-content: center;
  font-size: 1.15em;
}

.list-path-items {
  max-width: 45em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
}

@media (max-width:576px) {
  .type-wrap{
    text-align: center;
  }
}

@keyframes astronautAnim {
  0%{
    transform: translateY(15px);
  }25%{
    transform: translateY(-15px);
  }50%{
    transform: translateY(15px);
  } 75%{
    transform: translateY(-15px);
  } 100%{
    transform: translateY(15px);
  }
}
