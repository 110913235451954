@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap);
*{
  scroll-behavior: smooth;
}

html,body{
  height: 100%;
  width: 100%;
}

*::-webkit-scrollbar{
  width: .5em;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(94, 94, 94);
  border-radius: 20px;
}

img{
  object-fit: cover;
}

#root{
  height: 100%;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  color: #fff;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.navbar {
    width: 100%;
    font-size: 1.20em;
    position: -webkit-sticky;
    position: sticky;
    top:0;
    z-index: 1000;
}

.navbar-nav{
    width: 100%;
}

#navBarBrand > img{
    rotate: 0deg;
    transition: rotate 1s;
}

#navBarBrand:hover > img{
    cursor: pointer;
    rotate: 180deg;
}

h1 {
  display: inline;
  font-size: 3em;
  font-weight: 600;
}

.typed-cursor {
  font-size: 3em;
  font-weight: 600;
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}

@keyframes typedjsBlink {
  50% {
    opacity: 0.0;
  }
}

.typed-fade-out {
  opacity: 0;
  transition: opacity .25s;
  animation: 0;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
  border-bottom: 2px solid black;
}

h4 {
  font-size: 1.5em;
  font-weight: 500;
}

#imgMain {
  width: 100%;
  animation: astronautAnim 10s ease-in-out infinite;
  filter: drop-shadow(15px 13px 10px #000);
}

p {
  font-size: 1.25em;
}

.grid-path-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-flow: dense;
  grid-auto-rows: 120px;
  grid-gap: 30px;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
}

.path-item {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
  margin: auto;
}

.holidays {
  margin: .5em;
  padding: 1em;
  border: 3px solid black;
  border-radius: .75em;
}

.holidays-path-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.holidays-path-items>img {
  padding: .75em;
}

.grade-achievements {
  display: flex;
  padding-top: 1em;
  justify-content: center;
  font-size: 1.15em;
}

.list-path-items {
  max-width: 45em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  grid-gap: 10px;
  gap: 10px;
}

@media (max-width:576px) {
  .type-wrap{
    text-align: center;
  }
}

@keyframes astronautAnim {
  0%{
    transform: translateY(15px);
  }25%{
    transform: translateY(-15px);
  }50%{
    transform: translateY(15px);
  } 75%{
    transform: translateY(-15px);
  } 100%{
    transform: translateY(15px);
  }
}

.card-skill{
    height: 100%;
    cursor: pointer;
}

#skillsContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5em,9em));
    grid-auto-rows: 15em;
    grid-gap: 20px;
    gap: 20px;
    grid-auto-flow: dense;
    justify-content: space-evenly;
    align-items: center;
}

.skillFocus{
    border: 4px solid black;
}

.card-img-top-skill{
    padding: 1em;
    max-width: 8.5em;
    max-height: 8.5em;
    object-fit: contain;
    margin: auto;
}

.card-title-skill{
    text-align: center;
}

.datosPersonales{
    text-align: left;
    margin:auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 0;
}

.imgPersonalContainer{
    text-align: center;
    margin-bottom: 0;
}

.listaDatos{
    list-style-type: none;
    padding-left: 0;
    margin: auto;
}

.listaDatos > li {
    font-size: 1.1em;
}

.listaDatos > li > span {
    font-weight: bold;
    font-size: large;
}

@media (min-width: 992px) {
    .datosPersonales{
        text-align: right;
        width: auto;
        margin-bottom: 2em;
    }

    .imgPersonalContainer{
        text-align: left;
        margin-bottom: 2em;
    }
}

@media (max-width: 360px) {
    .listaDatos > li > span {
        font-weight: bold;
        font-size: small;
    }

    .listaDatos > li {
        font-size: small;
    }
}

#imgPersonal{
    width: 100%;
    max-width: 25em;
    border-radius: 3em;
}

#animationWrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
    margin-bottom: 2em;
}

#gameContainer{
    width: 80%;
    max-width: 35em;
    height: 20em;
    border: 2px solid #000000;
    border-radius: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-color: rgb(15, 15, 15);
}

#btnReiniciar:hover{
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.75);
}

.circle {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1em #000;
    transition: box-shadow 1.3s;
    position: absolute;
    z-index: 200;
}

.circle:hover{
    box-shadow: 0px 0px 15px 12px rgba(0,0,0,0.75);
}

.circle > div:first-child {
    display: flex;
    width: 62%;
    height: 62%;
    border-radius: 50%;
    background: #fff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#btnPlay{
    -webkit-clip-path: polygon(100% 50%, 0 0, 0 100%);
            clip-path: polygon(100% 50%, 0 0, 0 100%);
    background-color: #000;
    width: 50%;
    height: 50%;
    margin-left: .5em;
}

#animatedGrid{
    width: 90%;
    height: 80%;
    display: none;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-size: 4em 4em;
    opacity: 0;
    align-items: center;
    justify-items: center;
}

.gridItemHolder{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.animatedGridItem{
    width: .75em;
    height: .75em;
    transition: margin .25s;
    border-radius: 50%;
    transition: width .5s, height .5s, rotate .5s;
}

.secondaryHoverItem{
    transition: width .5s,height .5s;
}

.imgGatos, .imgGames{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 50em;
    margin: auto;
    grid-gap: 30px;
    gap: 30px;
    margin-bottom: 2em;
}

.imgGames{
    max-width: none;
}

.imgGatos > img {
    min-width: 10em;
    max-width: 15em;
    margin-bottom: 2em;
    border-radius: 1em;
    box-shadow: 13px 10px 12px 2px rgba(0,0,0,0.68);
}

.imgGames > img{
    max-width: 25em;
    min-width: 10em;
    border-radius: 1.5em;
}

img[alt='dark souls bonfire']{
    box-shadow: 13px 10px 12px 2px rgba(0,0,0,0.68);
}

.bounceAnimSpan{
    animation: waveAnim 5s infinite;
    display: inline-block;
    position: relative;
    letter-spacing: 5px;
}

@keyframes waveAnim {
    0%,40%,100%{
        transform: translateY(0);
    }
    20%{
        transform: translateY(-40px);
    }
}

.movies{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7em,9em));
    grid-gap: 20px;
    gap: 20px;
    grid-auto-flow: dense;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2em;
}

@media (max-width: 331px){
    .movies{
        grid-template-columns: repeat(auto-fit, 7em);
    }
}

@media (min-width: 768px) and (max-width: 1200px){
    .movies{
        grid-template-columns: repeat(3, 1fr);
    }
}

.movieCard{
    max-width: 12em;
    box-shadow: 13px 10px 12px 2px rgba(0,0,0,0.68);
    cursor: pointer;
}

.tituloPelicula, .tituloCancion{
    max-height: 88px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    display: -moz-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.canciones{
    display: grid;
    grid-template-columns: repeat(3, minmax(10em, 18em));
    grid-gap: 30px;
    gap: 30px;
    justify-content: space-around;
    align-items: center;
}

.songCard{
    height: 100%;
}

.songCardBody{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 5px;
    gap: 5px;
}

.spotifyButton{
    background-color: #1DB954;
    border: none;
    align-self: flex-end;
    display: flex;
    justify-self: flex-end;
    margin-top: auto;
}

.autorCancion{
    margin-bottom: 1em;
    color: rgb(70, 70, 70);
}

.autorCancion:hover{
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 575px) {
    .canciones{
        grid-template-columns: 1fr;
    }

    .songCard{
        display: flex;
        flex-direction: row;
    }

    .songCardBody{
        padding: .5em;
    }

    .songCard > img{
        max-width: 5em;
        max-height: 5em;
        margin: auto 1em;
    }

    .autorCancion{
        margin-bottom: auto;
    }
}
.formContainer{
    height: calc(100% - 76px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: box-shadow 1s;
}

form{
    width: 90%;
    max-width: 55em;
    height: 85%;
    min-height: 25em;
    max-height: 40em;
    padding: 2em 1em;
    background: #fff;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.contactform:hover{
    box-shadow:3px 3px 9px 4px rgba(0,0,0,0.39);
}

form > div:first-child{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

#emailImg{
    padding: .5em;
    max-width: 4em;
}

@media (max-width: 320px) {
    #emailImg{
        display: none;
    }
}


.encabezadoForm{
    font-size: xx-large;
    font-weight: 500;
    color: #000000;
    margin: 0;
}

.form_div{
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.formMessage{
    resize: none;
    height: 50%;
}

.form_div-input, .formMessage{
    width: 100%;
    border-radius: .5em;
    font-size: large;
    border: 2px solid black;
    padding: .25em;
    text-align: left;
    transition: border .25s;

}

.form_div-input:focus, .formMessage:focus{
    background: rgba(233, 233, 233, 0.5);
    outline: none;
}

.form_div-input-error{
    border: 2px solid rgb(255, 0, 0);
}

.form_div-input-error::placeholder{
    color: rgb(255, 0, 0);
    transition: color .25s;
}

#btnEnviar{
    width: 70%;
    max-width: 20em;
    height: 2.75em;
    font-size: medium;
    padding: .5em 1em;
    border-radius: .25em;
    background: rgb(0, 135, 255);
    color: white;
    border: none;
    transition: filter .5s, box-shadow .5s;
}

#btnEnviar:hover{
    box-shadow: 0px 3px 10px 2px #000000;
}

#btnEnviar:active{
    animation-name: buttonClickAnim;
    animation-duration: .25s;
    animation-direction: alternate-reverse;
}

@keyframes buttonClickAnim {
    from{
        transform: translate(0);
    }
    to{
        transform: translate(0,2px);
    }
}

.contactFormAlert{
    display: block;
    text-align: center;
    border: 1px solid #000;
    position: absolute;
    font-size: .85em;
    bottom: 2em;
    background: rgb(19, 19, 19);
    border-radius: 2em;
    max-width: 90%;
    padding: .8em 1em;
    display: none;
    animation: alertFadeAnim 4s ease-in-out 1 forwards;
}

@keyframes alertFadeAnim {
    0%{
        filter: opacity(0);
    }25%{
        filter: opacity(1);
    }50%{
        filter: opacity(1);
    }75%{
        filter: opacity(1);
    }100%{
        filter: opacity(0);
    }
}
img[alt="rocket"]{
    max-width: 8em;
}

.notFoundContainer{
    position: relative;
    height: calc(100% - 76px);
    margin: auto;
    overflow: hidden;
}

#circle-orbit-container{
    display: flex;
    justify-content: center;
}

#outer-orbit {
    position: absolute;
    width: 80em;
    height: 80em;
    animation: spin-right 9s linear infinite;
}

#circle-orbit-container > img {
    position: absolute;
    bottom: 0;
}

@keyframes spin-right {
    100% {
      transform: rotate(360deg);
    }
}

.appContent{
    height: 100%;
    width: 100%;
    padding: 0;
    background-color: rgb(48, 48, 48);
    overflow: auto;
    overflow: overlay;
}

