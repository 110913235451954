.datosPersonales{
    text-align: left;
    margin:auto;
    width: fit-content;
    margin-bottom: 0;
}

.imgPersonalContainer{
    text-align: center;
    margin-bottom: 0;
}

.listaDatos{
    list-style-type: none;
    padding-left: 0;
    margin: auto;
}

.listaDatos > li {
    font-size: 1.1em;
}

.listaDatos > li > span {
    font-weight: bold;
    font-size: large;
}

@media (min-width: 992px) {
    .datosPersonales{
        text-align: right;
        width: auto;
        margin-bottom: 2em;
    }

    .imgPersonalContainer{
        text-align: left;
        margin-bottom: 2em;
    }
}

@media (max-width: 360px) {
    .listaDatos > li > span {
        font-weight: bold;
        font-size: small;
    }

    .listaDatos > li {
        font-size: small;
    }
}

#imgPersonal{
    width: 100%;
    max-width: 25em;
    border-radius: 3em;
}

#animationWrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2em;
    margin-bottom: 2em;
}

#gameContainer{
    width: 80%;
    max-width: 35em;
    height: 20em;
    border: 2px solid #000000;
    border-radius: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-color: rgb(15, 15, 15);
}

#btnReiniciar:hover{
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.75);
}

.circle {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1em #000;
    transition: box-shadow 1.3s;
    position: absolute;
    z-index: 200;
}

.circle:hover{
    box-shadow: 0px 0px 15px 12px rgba(0,0,0,0.75);
}

.circle > div:first-child {
    display: flex;
    width: 62%;
    height: 62%;
    border-radius: 50%;
    background: #fff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#btnPlay{
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    background-color: #000;
    width: 50%;
    height: 50%;
    margin-left: .5em;
}

#animatedGrid{
    width: 90%;
    height: 80%;
    display: none;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-size: 4em 4em;
    opacity: 0;
    align-items: center;
    justify-items: center;
}

.gridItemHolder{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.animatedGridItem{
    width: .75em;
    height: .75em;
    transition: margin .25s;
    border-radius: 50%;
    transition: width .5s, height .5s, rotate .5s;
}

.secondaryHoverItem{
    transition: width .5s,height .5s;
}

.imgGatos, .imgGames{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 50em;
    margin: auto;
    gap: 30px;
    margin-bottom: 2em;
}

.imgGames{
    max-width: none;
}

.imgGatos > img {
    min-width: 10em;
    max-width: 15em;
    margin-bottom: 2em;
    border-radius: 1em;
    box-shadow: 13px 10px 12px 2px rgba(0,0,0,0.68);
}

.imgGames > img{
    max-width: 25em;
    min-width: 10em;
    border-radius: 1.5em;
}

img[alt='dark souls bonfire']{
    box-shadow: 13px 10px 12px 2px rgba(0,0,0,0.68);
}

.bounceAnimSpan{
    animation: waveAnim 5s infinite;
    display: inline-block;
    position: relative;
    letter-spacing: 5px;
}

@keyframes waveAnim {
    0%,40%,100%{
        transform: translateY(0);
    }
    20%{
        transform: translateY(-40px);
    }
}

.movies{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7em,9em));
    gap: 20px;
    grid-auto-flow: dense;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2em;
}

@media (max-width: 331px){
    .movies{
        grid-template-columns: repeat(auto-fit, 7em);
    }
}

@media (min-width: 768px) and (max-width: 1200px){
    .movies{
        grid-template-columns: repeat(3, 1fr);
    }
}

.movieCard{
    max-width: 12em;
    box-shadow: 13px 10px 12px 2px rgba(0,0,0,0.68);
    cursor: pointer;
}

.tituloPelicula, .tituloCancion{
    max-height: 88px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    display: -moz-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.canciones{
    display: grid;
    grid-template-columns: repeat(3, minmax(10em, 18em));
    gap: 30px;
    justify-content: space-around;
    align-items: center;
}

.songCard{
    height: 100%;
}

.songCardBody{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}

.spotifyButton{
    background-color: #1DB954;
    border: none;
    align-self: flex-end;
    display: flex;
    justify-self: flex-end;
    margin-top: auto;
}

.autorCancion{
    margin-bottom: 1em;
    color: rgb(70, 70, 70);
}

.autorCancion:hover{
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 575px) {
    .canciones{
        grid-template-columns: 1fr;
    }

    .songCard{
        display: flex;
        flex-direction: row;
    }

    .songCardBody{
        padding: .5em;
    }

    .songCard > img{
        max-width: 5em;
        max-height: 5em;
        margin: auto 1em;
    }

    .autorCancion{
        margin-bottom: auto;
    }
}