.navbar {
    width: 100%;
    font-size: 1.20em;
    position: sticky;
    top:0;
    z-index: 1000;
}

.navbar-nav{
    width: 100%;
}

#navBarBrand > img{
    rotate: 0deg;
    transition: rotate 1s;
}

#navBarBrand:hover > img{
    cursor: pointer;
    rotate: 180deg;
}
