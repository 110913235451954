.formContainer{
    height: calc(100% - 76px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: box-shadow 1s;
}

form{
    width: 90%;
    max-width: 55em;
    height: 85%;
    min-height: 25em;
    max-height: 40em;
    padding: 2em 1em;
    background: #fff;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.contactform:hover{
    box-shadow:3px 3px 9px 4px rgba(0,0,0,0.39);
}

form > div:first-child{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

#emailImg{
    padding: .5em;
    max-width: 4em;
}

@media (max-width: 320px) {
    #emailImg{
        display: none;
    }
}


.encabezadoForm{
    font-size: xx-large;
    font-weight: 500;
    color: #000000;
    margin: 0;
}

.form_div{
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.formMessage{
    resize: none;
    height: 50%;
}

.form_div-input, .formMessage{
    width: 100%;
    border-radius: .5em;
    font-size: large;
    border: 2px solid black;
    padding: .25em;
    text-align: left;
    transition: border .25s;

}

.form_div-input:focus, .formMessage:focus{
    background: rgba(233, 233, 233, 0.5);
    outline: none;
}

.form_div-input-error{
    border: 2px solid rgb(255, 0, 0);
}

.form_div-input-error::placeholder{
    color: rgb(255, 0, 0);
    transition: color .25s;
}

#btnEnviar{
    width: 70%;
    max-width: 20em;
    height: 2.75em;
    font-size: medium;
    padding: .5em 1em;
    border-radius: .25em;
    background: rgb(0, 135, 255);
    color: white;
    border: none;
    transition: filter .5s, box-shadow .5s;
}

#btnEnviar:hover{
    box-shadow: 0px 3px 10px 2px #000000;
}

#btnEnviar:active{
    animation-name: buttonClickAnim;
    animation-duration: .25s;
    animation-direction: alternate-reverse;
}

@keyframes buttonClickAnim {
    from{
        transform: translate(0);
    }
    to{
        transform: translate(0,2px);
    }
}

.contactFormAlert{
    display: block;
    text-align: center;
    border: 1px solid #000;
    position: absolute;
    font-size: .85em;
    bottom: 2em;
    background: rgb(19, 19, 19);
    border-radius: 2em;
    max-width: 90%;
    padding: .8em 1em;
    display: none;
    animation: alertFadeAnim 4s ease-in-out 1 forwards;
}

@keyframes alertFadeAnim {
    0%{
        filter: opacity(0);
    }25%{
        filter: opacity(1);
    }50%{
        filter: opacity(1);
    }75%{
        filter: opacity(1);
    }100%{
        filter: opacity(0);
    }
}