.card-skill{
    height: 100%;
    cursor: pointer;
}

#skillsContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5em,9em));
    grid-auto-rows: 15em;
    gap: 20px;
    grid-auto-flow: dense;
    justify-content: space-evenly;
    align-items: center;
}

.skillFocus{
    border: 4px solid black;
}

.card-img-top-skill{
    padding: 1em;
    max-width: 8.5em;
    max-height: 8.5em;
    object-fit: contain;
    margin: auto;
}

.card-title-skill{
    text-align: center;
}
